.loader-aligment{
	height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
 }

 .custom-layout{
 	background: #efefef;
    overflow-x: hidden;
    height : 100vh;
}