// Colors
//
// Grayscale and brand colors for use across Bootstrap.

// Create grayscale
$gray-dark:                 #1A3753;
$gray:                      #4E4D4A;
$gray-light:                #636c72;
$gray-lighter:              #eceeef;
$gray-lightest:             #E2ECF1;
$gray-lightest-background:  #F5F5F5;

// Reassign color vars to semantic color scheme
$text-dark:                 #193652;
$link-color:                #073EBE;
$icon-color:                #073EBE;
$brand-primary:             #09819A;
$brand-ascent:              #09819A;
$brand-light:               #EAF3FB;
$brand-success:             $green;
$brand-info:                $teal;
$brand-warning:             $orange;
$brand-danger:              $red;
$brand-inverse:             #0D1F2D;
$brand-danger-light:        #FFCDD2;
$brand-success-light:       #C8E6C9;
$brand-success-dark:        #A5D6A7;
$brand-warning-light:       #FFECB3;

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $gray-lightest-background;
$body-color:                #3d556b;
$inverse-bg:                $brand-inverse;
$inverse-color:             $gray-lighter;
$placeholder-color:         #aaa;

// Links
//
// Style anchor elements.

$link-color:                #0F2BB4;
$link-decoration:           none;
$link-hover-color:          $brand-primary;
$link-hover-decoration:     none;
